export default {
  init() {
    // JavaScript to be fired on the home page

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    const redirectSELECTED = () => {
      location.href = '/selected/';
    }
    document.getElementById('homePageRedirect').addEventListener('click', redirectSELECTED);
    document.getElementById('homePageRedirect').addEventListener('touchstart', redirectSELECTED);
  },
};
