// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

(function() {
    var origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function() {
        console.log('request started!');
        this.addEventListener('load', function() {
            setTimeout(() => {
              if(typeof jQuery('.elementor-image-carousel:not(.slick-initialized)').slick !== 'undefined'
              && typeof jQuery('.elementor-image-carousel:not(.slick-initialized)').slick === 'function'){
                jQuery('.elementor-image-carousel:not(.slick-initialized)').slick()
              }
            },50);
        });
        origOpen.apply(this, arguments);
    };
})();


//
// (function ( $ ) {
// 	'use strict';
//
// 	// detects when the ajax request has finished and the content has been updated
// 	// re-init the layout scripts from Elementor
// 	$(document).on('sf:ajaxfinish', 'article', function(){
//     console.log('Ajax');
//
// 	});
//
// }(jQuery));
