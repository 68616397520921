export default {
  init() {
    document.querySelectorAll('.slider-session').forEach(slidersession => {
      slidersession.querySelectorAll('.sectiontitle .elementor-widget-container').forEach(title => {
        title.addEventListener('mouseover', () => {
          slidersession.querySelectorAll('.block-desc').forEach(el => {
            el.style.display = 'block';
            el.addEventListener('mouseout', () => {
              el.style.display = 'none';
            });
          });
        });
      })
    });
  },
  finalize() {
    $(document).on('click tap', 'a[href^="#elementor-action"]', () => {
      setTimeout(function(){
        document.querySelectorAll('.customPopup').forEach(popup => {
          popup.querySelectorAll('a').forEach(link => {
            if(link.href===window.location.href.toString()){
              link.style.color='#737373';
              link.querySelectorAll('span').forEach(span => {
                span.style.color='#737373';
              });
            }
          });
        });
      }, 400);
    });
    const initLightbox = () => {
      DialogsManager.getWidgetType('lightbox').prototype.onShow = function(){
        document.body.style.overflow = 'hidden';
        document.body.style.WebkitOverflowScrolling = 'auto';
      }
  
      DialogsManager.getWidgetType('lightbox').prototype.onHide = function(){
        document.body.style.overflow = 'scroll';
        document.body.style.WebkitOverflowScrolling = 'touch';
      }
    }

    function readyFn() {
      setTimeout(function(){
        document.querySelectorAll('.Gallerie-projet').forEach(slider => {
          let cpt = 0;
          slider.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)').forEach(() => {
            cpt++;
          });
          if(slider.getElementsByClassName('swiper-slide-active')[0]) {
            let current=parseInt(slider.getElementsByClassName('swiper-slide-active')[0].dataset.swiperSlideIndex)+1;
            slider.querySelectorAll('.imgname').forEach(imgname => {
              imgname.innerHTML = current+'/'+cpt;
            });
          }
          else {
            slider.querySelectorAll('.imgname').forEach(imgname => {
              imgname.innerHTML = '1/1';
            });
          }
        });
            //test if DialogsManager exist else wait 150ms
        if (typeof DialogsManager !== 'undefined') {
          initLightbox();
        }
      }, 50);
    }

    let eeLoopAdded = document.querySelectorAll('.ee-loop');
    let config = { attributes: true, childList: true };
    let observer = new MutationObserver(readyFn);
    if (eeLoopAdded.length > 0) {
      observer.observe(eeLoopAdded[0], config);
    }

    $(document).on('click mousemove touchmove', readyFn);
    $(document).ready(readyFn);
  },
};
